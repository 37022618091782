<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body d-flex p-10">
               <div class="col">
                  <ul class="nav nav-tabs2">
                     <li class="nav-item">
                        <a class="nav-link show active" data-toggle="tab" href="#one">
                           <i class="far fa-cog font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("giveaway.settings") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#two">
                           <i class="far fa-users font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("giveaway.participants") }}</span>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="w-max-content">
                  <button class="btn btn-primary h-auto rounded-sm ml-2" @click="raffleGiveaway" v-if="settings.winner == null && settings.participants != null && settings.participants.length > 0">
                     <i class="fal fa-trophy font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("giveaway.winner.get") }}</span>
                  </button>
                  <button class="btn btn-primary h-auto rounded-sm ml-2" @click="endGiveaway" v-if="settings.winner != null && settings.status == 'Opened'">
                     <i class="fal fa-check font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("giveaway.finish") }}</span>
                  </button>
                  <button class="btn btn-outline-primary h-auto rounded-sm ml-2" @click="getSettings" v-if="settings.status == 'Opened'">
                     <i class="fal fa-sync font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("giveaway.update") }}</span>
                  </button>
                  <button class="btn btn-primary h-auto rounded-sm ml-2" @click="deleteGiveaway" v-if="settings.status == 'Opened'">
                     <i class="fal fa-trash font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("giveaway.delete") }}</span>
                  </button>
                  <button class="btn btn-primary h-auto rounded-sm ml-2" @click="startGiveaway" v-if="settings.status == null || settings.status == 'Finished'">
                     <i class="fal fa-check font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("giveaway.start.button") }}</span>
                  </button>
               </div>
            </div>
         </div>

         <div class="tab-content mt-0">
            <div class="tab-pane show active" id="one">
               <div class="row">
                  <div class="col-12">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <h6 class="limitador my-0 cursor-pointer" title="Edit" @click="editChannel">
                              <span>{{ $t("giveaway.selected") }}: <span class="ml-1" v-if="settings.nameChannel != null">#{{ settings.nameChannel }}</span>
                              <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                           </h6>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.start != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("giveaway.giveAwayDesc") }}</h6>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.start.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.start)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.start.enabled" :message="preview.start" :dadosUsuario="dadosUsuario" />
                  </div>
            
                  <div class="col-md-6 col-xl-4" v-if="preview.end != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("giveaway.winner.title") }}</h6>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.end.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.end)"><i class="far fa-pen font-11 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.end.enabled" :message="preview.end" :dadosUsuario="dadosUsuario" />
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="two">
               <div class="row">
                  <div class="col-12">
                     <div class="text-center my-5" v-if="settings.participants == null || settings.participants.length == 0">{{ $t("giveaway.winner.noParticipant") }}</div>
                     <div class="cards-1">
                        <participant v-for="(participant, index) in settings.participants" :key="index" :participant="participant" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalChannel -->
      <div class="modal fade" id="modalChannel" tabindex="-1" aria-labelledby="modalChannelLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalChannelLabel">{{ $t("serverSettings.selectChannel.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-0">{{ $t("serverSettings.selectChannel.sub") }}</h6>
                                 <hr>
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="settings.idChannel" @selectChannel="saveChannel($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.selectChannel.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ $t("serverSettings.editMessage.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import MessageDeck from '@/components/MessageDeck.vue'
import channel from '@/components/whitelist/Channel.vue'
import participant from '@/components/giveaway/Participant.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
   name: 'Giveaway',
   data: function () {
		return {
         message: null,
         settings: {"id": null, "idChannel": null, "nameChannel": null, "idServer": null, "participants": null, "status": null, "winner": null},
         preview: {'start': null, 'end': null}
      }
   },
   computed: {
		...mapState({
         dadosUsuario: state => state.dadosUsuario,
         channelsList: state => state.channelsList,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
	},
   components: {
      channel, PreviewMessageDeck, MessageDeck, participant
   },
   methods: {
      getSettings : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getsettingsgiveaway',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.preview = response.data.preview;
            this.settings = response.data.settings;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired"),
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editChannel : function () {
         $('#modalChannel').modal('show')
      },
      saveChannel : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.settings.idChannel = channel.id;
         this.settings.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterchannelgiveaway',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            $('#modalChannel').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.saved")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      startGiveaway : function () {
			Swal.fire({
				icon: 'warning',
            title: this.$t("serverSettings.swal.giveAway.start"),
            confirmButtonText: this.$t('serverSettings.swal.btnConfirm'),
            showCancelButton: true,
            cancelButtonText: this.$t('serverSettings.swal.btnCancel')

         }).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'servicos/startgiveaway',
						params: {
							idServer: this.serverS.id_s
						}
					}).then(response => {
						this.settings = response.data;

						this.$toast.fire({
                     icon: 'success',
                     title: this.$t("serverSettings.swal.giveAway.started")
                  })
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: this.$t("serverSettings.swal.expired")
								});
							} else if (error.response.status == 400) {
								this.$toast.fire({
									icon: 'error',
									title: this.$t("serverSettings.swal.invalidChannel")
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Error: ' + error.response.status
								});
							}
						} else {
							this.$toast.fire({
								icon: 'error',
								title: error
							});
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		},
      editPreview : function (preview) {
         this.message = null;

         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
      attObjetoMessage: function(e) {
			this.message = e;
		},
      savePreview : function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getSettings()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      deleteGiveaway : function () {
			Swal.fire({
				icon: 'warning',
            title: this.$t("serverSettings.swal.giveAway.delete"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'servicos/deletegiveaway',
						params: {
							idServer: this.serverS.id_s
						}
					}).then(response => {
						this.settings = response.data;
                  $('a.nav-link[href="#one"]').click();

						this.$toast.fire({
							icon: 'success',
							title: this.$t("serverSettings.swal.giveAway.deleted")
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: this.$t("serverSettings.swal.expired")
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Error: ' + error.response.status
								});
							}
						} else {
							this.$toast.fire({
								icon: 'error',
								title: error
							});
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		},
		raffleGiveaway : function () {
			Swal.fire({
				icon: 'warning',
            title: this.$t("serverSettings.swal.giveAway.winner"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'servicos/rafflegiveaway',
						params: {
							idServer: this.serverS.id_s
						}
					}).then(response => {
						this.settings = response.data;
                  $('a.nav-link[href="#two"]').click();

						Swal.fire({
							icon: 'success',
							title: this.$t("serverSettings.swal.giveAway.selectedWinner") +' '+ this.settings.winner,
							confirmButtonText: 'Close'
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: this.$t("serverSettings.swal.expired")
								});
							} else if (error.response.status == 400) {
								this.$toast.fire({
									icon: 'error',
									title: this.$t("serverSettings.swal.limit")
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Error: ' + error.response.status
								});
							}
						} else {
							this.$toast.fire({
								icon: 'error',
								title: error
							});
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		},
		endGiveaway : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/endgiveaway',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
				this.settings = response.data;
            $('a.nav-link[href="#one"]').click();

				this.$toast.fire({
					icon: 'success',
					title: this.$t("serverSettings.swal.giveAway.ended")
				});
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		},
      testMessage : function (type, idChannel) {
         this.$store.dispatch('sendMessage', {'type': type, 'idServer': this.serverS.id_s, 'idChannel': idChannel}).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.messageSent")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('getChannels')
      this.getSettings()
   }
}

</script>