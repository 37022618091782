<template>
   <div class="card mb-0">
      <div class="card-body p-10">
         <div class="row m-0">
            <div class="w-max-content pr-2">
               <div class="circle">
                  <img class="rounded-circle" :src="participant.photo == null ? '' : participant.photo" @error="imageError">
               </div>
            </div>
            <div class="col align-self-center">
               <h6 class="limitador mb-0">{{ participant.name == null ? 'Unknown' : participant.name }}</h6>
               <i class="fal fa-trophy text-warning font-13 d-block mt-2" v-if="participant.winner"></i>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Participant',
   props: ['participant'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
   width: 50px;
   height: 50px;
}

</style>